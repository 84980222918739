<template>
  <div class="home">
    我是home页
    
  </div>
</template>

<script>
export default {
  name: "HomeView",
  data() {
    return {
    
    };
  },
};
</script>

<style lang="scss" scoped></style>
