<template>
  <div id="app">
    <router-view :key="$route.fullPath"/>
  </div>
</template>

<script>
export default {
  // Vue 组件的其他配置项

  mounted() {
    const meta = document.createElement('meta');
    meta.name = 'viewport';
    meta.content = 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no';
    document.head.appendChild(meta);
  }
}
</script>

<style>
/* 添加禁用用户选择文本的样式 */
* {
/*  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; */
}
html, body {
  width: 100%;
  height: 100%;
  background-color: #f3f5fa ;
}

#app {
  width: 100%;
  height: 100%;
}
</style>

