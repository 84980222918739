import axios from "axios";
import util from "@/assets/util/util";
//总接口域名
// axios.defaults.baseURL = process.env.NODE_ENV === 'production'?process.env.VUE_APP_API_BASE_URL : ''
// axios.defaults.baseURL = 'http://testm.allinpayjs.cn/'
// axios.defaults.baseURL = 'http://10.53.10.1:9218/'

export function getJsConfigs(data) {
  return new Promise(function (resolve, reject) {
    axios.post('/api/codePlate/getJsConfigs', data, {
		headers: { //头部参数
		  'iToken': (localStorage.getItem('iToken') ? localStorage.getItem('iToken') : '')
		}
      })
      .then(function (res) { //请求成功，response接收返回参数
        resolve(res.data);
      })
      .catch(function (err) { //请求失败，error接收失败原因
        reject(err);
      })
  });
}

export function fetchPrintContent(data) {
  return new Promise(function (resolve, reject) {
    axios.post('/api/codePlate/fetchPrintContent', data, {
		headers: { //头部参数
		  'iToken': (localStorage.getItem('iToken') ? localStorage.getItem('iToken') : '')
		}
      })
      .then(function (res) { //请求成功，response接收返回参数
        resolve(res.data);
      })
      .catch(function (err) { //请求失败，error接收失败原因
        reject(err);
      })
  });
}

export function refund(data) {
  return new Promise(function (resolve, reject) {
    axios.post('/api/pos/refund', data, {
		headers: { //头部参数
		  'iToken': (localStorage.getItem('iToken') ? localStorage.getItem('iToken') : '')
		}
      })
      .then(function (res) { //请求成功，response接收返回参数
        resolve(res.data);
      })
      .catch(function (err) { //请求失败，error接收失败原因
        reject(err);
      })
  });
}

export function checkAuthCode(data) {
  return new Promise(function (resolve, reject) {
    axios.post('/api/pos/checkAuthCode', data, {
		headers: { //头部参数
		  'iToken': (localStorage.getItem('iToken') ? localStorage.getItem('iToken') : '')
		}
      })
      .then(function (res) { //请求成功，response接收返回参数
        resolve(res.data);
      })
      .catch(function (err) { //请求失败，error接收失败原因
        reject(err);
      })
  });
}

export function queryPrepareOrder(data) {
  return new Promise(function (resolve, reject) {
    axios.post('/api/qrcode/queryPrepareOrder', data, {

      })
      .then(function (res) { //请求成功，response接收返回参数
        resolve(res.data);
      })
      .catch(function (err) { //请求失败，error接收失败原因
        reject(err);
      })
  });
}

export function getReceipt(data) {
  return new Promise(function (resolve, reject) {
    axios.get('/api/receipt', {
		headers: { //头部参数
		  'iToken': (localStorage.getItem('iToken') ? localStorage.getItem('iToken') : '')
		},
        params: data
      })
      .then(function (res) { //请求成功，response接收返回参数
        console.log(res)
        resolve(res.data);
      })
      .catch(function (err) { //请求失败，error接收失败原因
        reject(err);
      })
  });
}

export function queryBalance(data) {
  return new Promise(function (resolve, reject) {
    axios.get('api/bm/payMember/queryBalance/yunst', {
  		headers: { //头部参数
  		  'iToken': (localStorage.getItem('iToken') ? localStorage.getItem('iToken') : '')
  		},
        params: data
      })
      .then(function (res) { //请求成功，response接收返回参数
        console.log(res)
        resolve(res.data);
      })
      .catch(function (err) { //请求失败，error接收失败原因
        reject(err);
      })
  });
}

export function getUser(data) {
  return new Promise(function (resolve, reject) {
    axios.get('api/current/user', {
		headers: { //头部参数
		  'iToken': (localStorage.getItem('iToken') ? localStorage.getItem('iToken') : '')
		},
        params: data
      })
      .then(function (res) { //请求成功，response接收返回参数
        console.log(res)
        resolve(res.data);
      })
      .catch(function (err) { //请求失败，error接收失败原因
        reject(err);
      })
  });
}

export function vercode(data) {
  return new Promise(function (resolve, reject) {
    axios.get('api/anon/auth/vercode', {
        params: data
      })
      .then(function (res) { //请求成功，response接收返回参数
        console.log(res)
        resolve(res.data);
      })
      .catch(function (err) { //请求失败，error接收失败原因
        reject(err);
      })
  });
}

export function validate(data) {
  return new Promise(function (resolve, reject) {
    axios.post('api/anon/auth/validate', data, {

      })
      .then(function (res) { //请求成功，response接收返回参数
        resolve(res.data);
      })
      .catch(function (err) { //请求失败，error接收失败原因
        reject(err);
      })
  });
}

export function getReturnUrl(data) {
  return new Promise(function (resolve, reject) {
    axios.post('api/qrcode/getReturnUrl', data, {

      })
      .then(function (res) { //请求成功，response接收返回参数
        resolve(res.data);
      })
      .catch(function (err) { //请求失败，error接收失败原因
        reject(err);
      })
  });
}

export function authRedirectUrl(data) {
  return new Promise(function (resolve, reject) {
    axios.post('api/qrcode/authRedirectUrl', data, {

      })
      .then(function (res) { //请求成功，response接收返回参数
        resolve(res.data);
      })
      .catch(function (err) { //请求失败，error接收失败原因
        reject(err);
      })
  });
}

export function getChannelUserId(data) {
  return new Promise(function (resolve, reject) {
    axios.post('api/qrcode/getChannelUserId', data, {

      })
      .then(function (res) { //请求成功，response接收返回参数
        resolve(res.data);
      })
      .catch(function (err) { //请求失败，error接收失败原因
        reject(err);
      })
  });
}

export function getZdOrders(data) {
  return new Promise(function (resolve, reject) {
    axios.post('api/codePlate/getZdOrders', data, {

      })
      .then(function (res) { //请求成功，response接收返回参数
        resolve(res.data);
      })
      .catch(function (err) { //请求失败，error接收失败原因
        reject(err);
      })
  });
}

export function getHisOrders(data) {
  return new Promise(function (resolve, reject) {
    axios.post('api/codePlate/getHisOrders', data, {

      })
      .then(function (res) { //请求成功，response接收返回参数
        resolve(res.data);
      })
      .catch(function (err) { //请求失败，error接收失败原因
        reject(err);
      })
  });
}

export function getHisOrdersSum(data) {
  return new Promise(function (resolve, reject) {
    axios.post('api/codePlate/getHisOrdersSum', data, {
      headers:{
        'iToken':(localStorage.getItem('iToken')?localStorage.getItem('iToken'):'')
      }
      })
      .then(function (res) { //请求成功，response接收返回参数
        resolve(res.data);
      })
      .catch(function (err) { //请求失败，error接收失败原因
        reject(err);
      })
  });
}

export function paymp(data) {
  return new Promise(function (resolve, reject) {
    axios.post('api/qrcode/pay', data, {

      })
      .then(function (res) { //请求成功，response接收返回参数
        resolve(res.data);
      })
      .catch(function (err) { //请求失败，error接收失败原因
        reject(err);
      })
  });
}

export function getTermNoInfo(data) {
  return new Promise(function (resolve, reject) {
    axios.post('api/codePlate/getTermNoInfo', data, {

      })
      .then(function (res) { //请求成功，response接收返回参数
        resolve(res.data);
      })
      .catch(function (err) { //请求失败，error接收失败原因
        reject(err);
      })
  });
}

export function queryMch(data) {
  return new Promise(function (resolve, reject) {
    axios.post('api/qrcode/queryMch', data, {

      })
      .then(function (res) { //请求成功，response接收返回参数

        resolve(res.data);
      })
      .catch(function (err) { //请求失败，error接收失败原因
        reject(err);
      })
  });
}

function sendGetRequest(url, data) {
  return new Promise(function (resolve, reject) {
    axios.get(url, {
        headers: { //头部参数
          'iToken': (localStorage.getItem('iToken') ? localStorage.getItem('iToken') : '')
        },
        params: data
      })
      .then(function (res) { //请求成功，response接收返回参数
        if (res.data.code == 401) {
          console.log('iToken失效发送错误')
          util.$emit('uniToken')
        }
        resolve(res);
      })
      .catch(function (err) { //请求失败，error接收失败原因
        reject(err);
      })
  });
}

function sendPostLogin(url, data) {

  return new Promise(function (resolve, reject) {
    axios.post(url, data, {

      })
      .then(function (res) { //请求成功，response接收返回参数

        resolve(res);
      })
      .catch(function (err) { //请求失败，error接收失败原因
        reject(err);
      })
  });
}

function sendGetDownload(url, data) {
  return new Promise(function (resolve, reject) {
    axios.get(url, {
      headers: {
        'iToken': (localStorage.getItem('iToken') ? localStorage.getItem('iToken') : '')
      },
      params:data,
      responseType:'blob'
    }).then(function(res){
      if(res.data.code ==401){
        console.log('iToken失效发送错误');
        util.$emit('uniToken')
      }
      resolve(res)
    }).catch(function(err){
      reject(err)
    })
  })
}

function sendPostRequest(url,data){
  return new Promise(function(resolve,reject){
    axios.post(url,data,{
      headers:{
        'iToken':(localStorage.getItem('iToken')?localStorage.getItem('iToken'):'')
      }
    }).then((res)=>{
      if(res.data.code == 401){
        console.log('iToken失效发送错误');
        util.$emit('uniToken')
      }
      resolve(res)
    }).catch((err)=>{
      reject(err)
    })
  })
}

//接口列表
export default {
  //防止没有一个post/get会报错，写一个默认的
  get(data){
    return sendGetRequest('',data)
  },
  post(data){
    return sendPostRequest('',data)
  },
  //接口开始
  //登录
  getToken(data){
    return sendPostLogin('',data)
  }
}























